<template>
  <div>
    <el-card shadow="hover" class="card mb-3 permissionse-card" style="width: 100%" v-loading="loading" element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Manage Permissions</h3>
      </div>
      <el-form ref="permission" :model="managePermissions" class="log-form">
        <template>
          <el-row>
            <el-col :span="12" v-if="!isFromEntities">
              <div class="ptl-1">
                <p class="mb-10">Select User Role</p>
                  <el-select v-model="managePermissions.userType"  @change="handleUserRoleSelection"
                    placeholder="Select User Type">
                    <el-option v-for="item in userTypes" :key="item._id" :label="getLabel(item)"
                      :value="item._id"></el-option>
                  </el-select>
           
              </div>
            </el-col>
          </el-row>
        </template>
        <el-row>
          <el-col :span="24">
            <el-card shadow="hover" class="card-body sidemenu">
              <div class="pt-1 p-1">
                <el-form-item>
                  <template v-for="(category, cIndex) in permissionsList">
                    <!-- <div v-if="getCategoryStatus(category)" :key="cIndex"> -->
                    <el-collapse v-model="activeTab" :key="cIndex">
                      <el-collapse-item :name="'' + (cIndex + 1)">
                        <template slot="title">
                          <el-checkbox :indeterminate="isIndeterminate[cIndex]" :label="category._id"
                            v-model="checkAll[cIndex]" @change="
                              handleChangeCategory(
                                '' + category._id,
                                category.name
                              )
                              ">{{ category.name }}</el-checkbox>
                        </template>
                        <section>
                          <ul>
                            <li class="
                                el-col
                                el-col-24
                                el-col-xs-12
                                el-col-sm-12
                                el-col-md-10
                                el-col-lg-12
                              " v-for="(
                                permission, index
                              ) in category.permissions" :key="index">
                              <el-checkbox :label="permission._id" v-model="managePermissions.userPermission" @change="
                                handleChangePermission(
                                  '' + category._id,
                                  category.name
                                )
                                ">{{ permission.name }}</el-checkbox>
                              <!-- <i class="el-icon-success"></i> -->
                            </li>
                          </ul>
                        </section>
                      </el-collapse-item>
                    </el-collapse>
                    <!-- </div> -->
                  </template>
                </el-form-item>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <p class="error" v-if="getManagePermissionsErrors &&
          getManagePermissionsErrors.critical_error
          ">
          {{ getManagePermissionsErrors.critical_error }}
        </p>
        <div class="card-footer bg-white" v-if="!isFromEntities">
          <el-form-item class="mb-0 p-2">
            <el-button @click="resetForm">Cancel</el-button>
            <el-button type="primary"  :disabled="!isUserRoleSelected" @click="onSubmit">Save
              Changes</el-button>

          </el-form-item>
        </div>
        <div class="float-right" v-if="isFromEntities">
          <el-form-item class="mb-0 p-2">
            <el-button @click="closeEntityPermissions">Cancel</el-button>
            <el-button style="background-color: #409eff; color: #ffffff" class="type-2" :loading="loading"
              @click="saveEntityPermissions">Save</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
//import _ from 'lodash';
export default {
  name: "Permissions",
  props: ["isFromEntities", "selectedEntity"],
  mixins: [MobileRelatedHelper],
  data() {
    return {
      defaultPermissions: [
        {
          cartegory: "Documents",
          name: "View Document",
          id: "6223293083dfdee72492ae7f",
        },
        //'View Document',
        //'View Contact Documents',
        //'View Template'
      ],
      userTypes: [],
      allRoles: [],
      permissionsList: [],
      passwordTypenew: "password",
      passwordType: "password",
      passwordTypeconfirm: "password",
      loading: false,
      managePermissions: {
        userType: "",
        role: "",
        userPermission: [],
      },
      //written by sekhar
      passwordError: [],
      password_rules: [
        {
          message: "Password shoud contain atleast one Lowercase",
          regex: /[a-z]+/,
        },
        {
          message: "Password shoud contain atleast one Uppercase",
          regex: /[A-Z]+/,
        },
        {
          message: "Password must be more than 8 characters long",
          regex: /.{6,}/,
        },
        {
          message: "Password shoud contain atleast one Numeric",
          regex: /[0-9]+/,
        },
        {
          message: "Password shoud contain atleast one special character",
          regex: /[!@#$%^&*]/,
        },
      ],
      activeTab: [],
      selectedCategories: [],
      isIndeterminate: [],
      checkAll: [],
      isUserRoleSelected: false,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getManagePermissionsErrors",
      "getUserTypeList",
      "getUserType",
      "getActiveWorkspace",
      "getPermissionList",
      "getPermissions",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),

    ...mapGetters("roles", ["getAllRoles"]),
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo", "getPayment", "getPaymentsHistory"]),
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
    getCategoryStatus() {
      return (category) => {
        if (this.getPaymentsHistory && this.getPaymentsHistory.data && this.getPaymentsHistory.data.length) {
          let ActiveSubscriptionInfo = this.getPaymentsHistory.data[0];
          // _.indexOf([1, 2, 3, 1, 2, 3], 2);
          // let index = this._.indexOf(ActiveSubscriptionInfo.planDetails.template_data.features, category.name);
          let index = ActiveSubscriptionInfo.planDetails.template_data.features.indexOf(category.name);
          if (index) {
            return true
          }
          else {
            return false
          }

        }
        else {
          return false
        }
      }
    },

    // get_all
  },
  mounted() {
    if (this.getActiveWorkspace.plan_type == "INDIVIDUAL") {
      this.$router.push({
        path: "/profile",
      });
    }
    this.getPermissionsList();
    this.getSubscriptionHistory();
    // this.getPermissionsListByCat();
    // this.fetchRoles();
    if (!this.isFromEntities) {
      this.getCompanyUserTypeList();
    } else {
      this.managePermissions.userPermission = [];
      if (
        this.selectedEntity?.permissions &&
        this.selectedEntity.permissions.length
      ) {
        this.managePermissions.userPermission = this.selectedEntity.permissions;
      }
    }
    this.$store.commit("auth/setManagePermissionsErrors", null, { root: true });
  },
  methods: {

    handleUserRoleSelection() {
    this.isUserRoleSelected = true;
    this.getPermissionsByUserType();
  },
    async getSubscriptionHistory() {
      try {
        // getPayments
        this.loading = true;
        let email = 'admin@bainfotech.in';
        await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", { email });
        if (this.getPaymentsHistory && this.getPaymentsHistory.planType==1)
        {
        this.getPermissionsListByCat();
        }
        this.loading = false;
      }
      catch (error) {
        this.loading = false;
        console.log("getSubscriptionHistory", error)
      }
    },
    
    async getPermissionsListByCat() {
        try {
          this.loading = true;
          let data = {
            categories: this.getPaymentsHistory.featuresList
          }
          await this.$store.dispatch("auth/getPermissionsByCat", data);
          if (this.getPermissionList) {
            this.permissionsList = this.getPermissionList;
          }

          this.loading = false;
        } catch (err) {
          console.log("getPermissionsListByCat", err);
        }
      },

    async getPermissionsList() {
      try {
        this.loading = true;
        if (this.getPermissionList) {
          // this.permissionsList = this.getPermissionList;
          await this.getPermissionList.map(async (cat) => {

            if (this.getPaymentsHistory && this.getPaymentsHistory.planType==1 &&this.getPaymentsHistory.data && this.getPaymentsHistory.data.length) {
              let ActiveSubscriptionInfo = this.getPaymentsHistory.data[0];
              // let index = this._.indexOf(ActiveSubscriptionInfo.planDetails.template_data.features, cat.name);
              let index = ActiveSubscriptionInfo.planDetails.template_data.features.indexOf(cat.name);
              if (index > 0) {
                this.permissionsList.push(cat)
              }
            }
            else{ 
              this.permissionsList = this.getPermissionList;
            }
          })
          this.permissionsList.map((p, pIndex) => {
            this.activeTab = [...this.activeTab, ...["" + (pIndex + 1)]];
          });
          if (
            this.selectedEntity &&
            ((this.isFromEntities && !this.selectedEntity.permissions) ||
              !this.selectedEntity.permissions.length)
          ) {
            // this.managePermissions.userPermission = this.selectedEntity.permissions;
            this.permissionsList.forEach((element) => {
              this.managePermissions.userPermission = [
                ...this.managePermissions.userPermission,
                ...(element.permissions || []).flatMap((e) => e._id),
              ];
            });
          }
        }
        this.loading = false;
      } catch (err) {
        console.log("getPermissionsList", err);
      }
    },
    async getCompanyUserTypeList() {
      try {
        await this.$store.dispatch(
          "auth/getUserTypeList",
          {companyId:this.getActiveWorkspace.company_id}
        );
        if (this.getUserTypeList) {
          this.userTypes = this.getUserTypeList;
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async fetchRoles() {
      this.loading = true;
      let params = {
        get_all: true,
      };
      await this.$store.dispatch("roles/fetchRoles", params);
      this.allRoles = this.getAllRoles ? this.getAllRoles.data : [];
      setTimeout(() => {
        this.loading = false;
        }, 2000);

    },
    async closeEntityPermissions() {
      this.$emit("close");
    },
    async saveEntityPermissions() {
      this.loading = true;
      this.$emit("save", this.managePermissions.userPermission);
    },
    async onSubmit() {
      try {
        let permissionSet = [];
        // let categoryName = []
        await this.permissionsList.map(async (c) => {
          await c.permissions.map((p) => {
            if (
              this.managePermissions.userPermission.includes(p._id.toString())
            ) {
              permissionSet = [...permissionSet, ...[p.slug]];
              // categoryName.push(c.name)
            }
          });
        });
        // if((categoryName && categoryName.includes('Documents') && permissionSet && permissionSet.length && permissionSet.includes('viewDocument')) ||
        //     permissionSet.length == 0 || (categoryName && categoryName != 'Documents'))
        // {
        let params = {
          id: this.managePermissions.userType,
          permissions: this.managePermissions.userPermission,
        };
        this.loading = true;
        await this.$store.dispatch("auth/updatePermissions", params);
        if (this.getPermissions) {
          this.getCompanyUserTypeList();
          await this.permissionsList.map(async (c, cIndex) => {
            this.checkAll[cIndex] = false;
            this.isIndeterminate[cIndex] = false;
          });
          if (
            this.managePermissions.userType.toString() ==
            this.getUserType._id.toString()
          ) {
            let user_type = this.getUserType;
            user_type.permissionSet = permissionSet;

            this.$store.commit("auth/setUserType", user_type, {
              root: true,
            });
          }
          this.$notify.success({
            title: "Success",
            message: "Role permissions updated successfully",
          });
          this.resetForm();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please Select Role To Add  Permissions",
          });
        }
        setTimeout(() => {
        this.loading = false;
        }, 2000);

        // }else{
        //   this.$message.warning("Please give 'View Document' permission to perform actions on the document...!")
        // }
      } catch (err) {
        console.log("onSubmit", err);
      }
    },
    // getRoleTypes() {
    //   this.getCompanyUserTypeList();
    // },
    getPermissionsByUserType() {
      this.loading = true;
      let typeIndex = this.userTypes.findIndex(
        (p) => p._id.toString() == this.managePermissions.userType.toString()
      );
      if (typeIndex != -1) {
         // release all the indexes from array start
         this.isIndeterminate=[];
        this.checkAll=[];
        this.managePermissions.userPermission=[];
        // release all the indexes from array end

        this.managePermissions.userPermission =
          this.userTypes[typeIndex].permissions;
        this.permissionsList.map(async (c) => {
          let permLength = c.permissions.length;
          let selLength = 0;
          await c.permissions.map((p) => {
            if (
              this.managePermissions.userPermission.includes(p._id.toString())
            ) {
              selLength++;
              // if(!this.checkAll[]){
              //   this.selectedCategories = [...this.selectedCategories, ...[c._id.toString()]];
              // }
            }
          });
          if (selLength == 0) {
            this.isIndeterminate.push(false);
            this.checkAll.push(false);
          }
          else if (selLength == permLength) {
            this.isIndeterminate.push(false);
            this.checkAll.push(true);
          }
          else if (selLength < permLength && selLength != 0) {
            this.isIndeterminate.push(true);
            this.checkAll.push(false);
          }
        });

        setTimeout(() => {
        this.loading = false;
        }, 2000);

      }
    },
    handleChangeCategory(catId) {
      let catIndex = this.permissionsList.findIndex(
        (c) => c._id.toString() == catId.toString()
      );
      let permissionListItem = this.permissionsList[catIndex];
      let existingPermission = permissionListItem.permissions.filter((p) =>
        this.managePermissions.userPermission.includes(p._id.toString())
      );
      let permLength = permissionListItem.permissions.length;
      let selLength = existingPermission.length;
      permissionListItem.permissions.map((p) => {
        if (
          !this.isIndeterminate[catIndex] &&
          existingPermission.length == permLength
        ) {
          selLength = 0;
          let index = this.managePermissions.userPermission.findIndex(
            (perm) => p._id.toString() == perm
          );
          this.managePermissions.userPermission.splice(index, 1);
        }
        if (
          (this.isIndeterminate[catIndex] &&
            existingPermission.length <= permLength) ||
          existingPermission.length == 0
        ) {
          if (
            !this.managePermissions.userPermission.includes(p._id.toString())
          ) {
            selLength++;
            this.managePermissions.userPermission = [
              ...this.managePermissions.userPermission,
              ...[p._id.toString()],
            ];
          }
        }
      });
      if (selLength == 0) {
        this.isIndeterminate[catIndex] = false;
        this.checkAll[catIndex] = false;
      }
      if (selLength == permLength) {
        this.isIndeterminate[catIndex] = false;
        this.checkAll[catIndex] = true;
      }
      if (selLength < permLength && selLength != 0) {
        this.isIndeterminate[catIndex] = true;
        this.checkAll[catIndex] = false;
      }
    },
    handleChangePermission(catId, name) {
      let catIndex = this.permissionsList.findIndex(
        (c) => c._id.toString() == catId.toString()
      );
      let permissionListItem = this.permissionsList[catIndex];
      let existingPermission = permissionListItem.permissions.filter((p) =>
        this.managePermissions.userPermission.includes(p._id.toString())
      );
      let permLength = permissionListItem.permissions.length;
      let selLength = existingPermission.length;
      if (name == "Documents") {
        this.defaultPermissions.filter((el) => {
          existingPermission.find((e) => {
            if (e.name === "Create Document") {
              return this.managePermissions.userPermission;
            } else if (el.name.toString() != e.name.toString()) {
              if (
                this.managePermissions.userPermission.includes(el.id.toString())
              ) {
                return this.managePermissions.userPermission;
              } else {
                this.managePermissions.userPermission = [
                  ...this.managePermissions.userPermission,
                  ...[el.id],
                ];
                if (selLength >= 1) {
                  this.$message.warning(
                    "Please revoke 'View Document' permissions to revoke all permissions.!"
                  );
                }
              }
            }
          });
        });
      }
      if (selLength == 0) {
        this.isIndeterminate[catIndex] = false;
        this.checkAll[catIndex] = false;
      }
      if (selLength == permLength) {
        this.isIndeterminate[catIndex] = false;
        this.checkAll[catIndex] = true;
      }
      if (selLength < permLength && selLength != 0) {
        this.isIndeterminate[catIndex] = true;
        this.checkAll[catIndex] = false;
      }
    },
    resetForm() {
      this.loading=true;
      this.managePermissions = {
        userType: "",
        userPermission: [],
      };
        // release all the indexes from array start
        this.isIndeterminate=[];
        this.checkAll=[];
        // release all the indexes from array end

      this.selectedCategories = [];
      this.$refs.permission.resetFields();
      setTimeout(() => {
        this.loading = false;
        }, 2000);
    },
  },
};
</script>
<style lang="scss">
.log-form {
  .ptl-1 {
    padding: 0.8rem 1.5rem !important;
  }

  .el-form-item-p-0 {
    margin-bottom: 0 !important;
  }

  .el-input__inner {

    //width: 40% !important;
    //padding: 0 1.5em;
    //line-height: 3.5em;
    //height: auto;
    //	font-size: 1.15em;
    //	border-color: #8692a6;
    //letter-spacing: 0.075em;
    &:hover,
    &:focus {
      border-color: #f754a2;
      background-color: #ffffff;
    }
  }

  .password-switch {
    position: absolute;
    right: 180px;
    top: 6%;
    color: #f754a2;
  }
}

.error {
  color: red;
}

.sidemenu {
  height: 525px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}

.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
</style>
<style lang="scss" scoped>
.permissionse-card{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform : scale(1.0);
}
</style>
